/* eslint-disable react-hooks/exhaustive-deps, no-console */
import React, { lazy, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import DashboardLayout from 'layout/DashboardLayout'
import MainLayout from 'layout/MainLayout'
import Sidebar from 'layout/Sidebar'
import { useDispatch } from 'react-redux'
import CompanyService from 'api/CompanyService'
import { setCompany } from 'actions/company'

const EditCampaignPage = lazy(() => import('pages/EditCampaignPage'))
const UserInformationPage = lazy(() => import('pages/UserInformationPage'))
const ManageExperiencesPage = lazy(() => import('pages/ManageExperiencesPage'))
const PerformancePage = lazy(() => import('pages/PerformancePage'))
const AnalyticsPage = lazy(() => import('pages/AnalyticsPage'))

const AuthenticatedApp = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const getCompanyInfo = async () => {
      try {
        const getCompanyResponse = await CompanyService.getCompany()
        const companyInfo = getCompanyResponse.data
        dispatch(setCompany(companyInfo))
      } catch (error) {
        console.log(error)
      }
    }
    getCompanyInfo()
  }, [])

  return (
    <DashboardLayout>
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <MainLayout isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}>
        <Switch>
          <Route path="/" exact>
            <ManageExperiencesPage />
          </Route>
          <Route exact path="/campaigns/:id">
            <EditCampaignPage />
          </Route>
          <Route exact path="/campaigns/:id/performance">
            <PerformancePage />
          </Route>
          <Route path="/user-information">
            <UserInformationPage />
          </Route>
          <Route path="/analytics">
            <AnalyticsPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </MainLayout>
    </DashboardLayout>
  )
}

export default AuthenticatedApp
