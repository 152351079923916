import axiosClient from './httpClient'

class AuthService {
  static signIn = ({ email, password }) =>
    axiosClient().post('/login', { email, password })

  static signOut = () => axiosClient().delete('/users/sign_out')
}

export default AuthService
