import React from 'react'
import PropTypes from 'prop-types'

import Item from './components/Item'
import Title from './components/Title'

import StyledMenu from './menu.styles'

const Menu = ({ children }) => <StyledMenu>{children}</StyledMenu>

Menu.Title = Title
Menu.Item = Item

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Menu
