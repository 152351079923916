export default {
  login: {
    welcome: 'Welcome!',
    legend: 'To start using VozLab, please log in to your account.',
    emailAddress: 'Email Address',
    emailAddressPlaceholder: 'Please type your email address',
    password: 'Password',
    passwordPlaceholder: 'Please type your password',
    btnLoginText: 'Login',
    invalidEmail: 'Invalid email address',
    requiredEmail: 'Email address is a required field',
    requiredPassword: 'Password is a required field',
    invalidCredentials: 'Invalid login credentials',
  },
  sidebar: {
    experiences: 'experiences',
    manage: 'manage',
    clone: 'clone',
    information: 'information',
    view: 'view',
    analytics: 'analytics',
    logout: 'log out',
  },
  editExperience: {
    configurations: {
      speakRightAnswerIfWrong:
        'Provide feedback on whether the answer provided was correct',
      termsAndConditionsEmail: 'Send Terms Of Service email',
    },
    option: 'Option',
    pleaseEnterOptions: 'Please enter options for',
    addOption: 'Add option',
    question: 'Question',
    hasAudioMessage:
      'Message cannot be edited since there is an audio message created.',
    previewMessages: 'Preview Messages',
    happyPath: 'Happy Path',
    editAddons: 'Edit add-ons',
    editMessages: 'Edit messages',
    editMessagesLegend: 'Type the experience messages here',
    askForPermission: 'Ask for permission',
    askForPermissionPlaceholder:
      'Please enable profile permissions in the Amazon Alexa app.',
    invalidCode: 'Invalid code',
    invalidCodePlaceholder:
      'The code is not a valid one, please enter a new one.',
    welcome: 'Welcome',
    welcomePlaceholder:
      "Hi there, today's giveaway is an Elf on the Shelf package, brought to you by Kellogg's",
    questionMessageHelp:
      "The question must evoke for a simple 'YES' or 'NO' answer from the users. E.g. Welcome to VozLab's quiz. Are you ready to start?'",
    codeAlreadyUsed: 'Code already used',
    codeAlreadyUsedPlaceholder:
      "The code you've entered has been already used, pleae enter a new code",
    askForCode: 'Ask for code',
    askForCodePlaceholder:
      "What's the code from you Kellogg's promotional pack?",
    errorCode: 'Error code',
    errorCodePlaceholder:
      "Sorry, I didn't get that, could you please try again?",
    askForMarketingPermission: 'Ask for marketing permissions',
    askForMarketingPermissionPlaceholder:
      "Thanks for entering Kellogg's giveaway today. Before you go, would you like to hear from Kellogg's promotions and special offers in the future?",
    askForMarketingPermissionYesIntentPlaceholder: 'Great! Thank you again!',
    askForMarketingPermissionNoIntentPlaceholder: 'Ok! Thank you again',
    help: 'Help message',
    helpTooltip:
      "A help message is displayed when a user asks for help after getting past the 'Welcome message'",
    helpPlaceholder:
      "Look inside the cereal package and find the promotional code. Once you've seen it please read it to me.",
    codeConfirmation: 'Code confirmation',
    codeConfirmationPlaceholder: "Thank you, just to confirm. Was that's ",
    codeNotConfirmed: 'Code not confirmed',
    codeNotConfirmedPlaceholder:
      "Ok, What's the code from you Kellogg's promotional pack?",
    goodbyeWithAddOns: 'Goodbye with Add-ons',
    goodbyeWithAddOnsPlaceholder: 'Great! Thank you again and good luck!',
    goodbyeWithoutAddOns: 'Goodbye without Add-ons',
    goodbyeWithoutAddOnsPlaceholder: 'Ok! Thanks you again and good luck!',
    rightAnswer: 'Right answer',
    rightAnswerPlaceholder: 'Your answer is correct!',
    wrongAnswer: 'Wrong answer',
    wrongAnswerPlaceholder: 'Oh no, your answer is wrong!',
    maximumAttempts: 'Maximum attempts',
    maximumAttemptsPlaceholder: '0',
    off: 'Off',
    offPlaceholder:
      "Hi there, we don't have any promotions available now, please check in a few days. Thanks, and see you soon.",
    stepsWon: 'Information about how to win',
    stepsWonTooltip:
      "Information about how to win is displayed when a user asks about winning after getting past the 'Welcome message'",
    stepsWonPlaceholder:
      'Winning entrants will be notified immediately bu me and via the email you used to enter the competition',
    endCompetition: 'When does the experience end?',
    endCompetitionTooltip: 'Type in information about when the experience ends',
    endCompetitionPlaceholdeR:
      'The promotion is open from 01 st June 2020 until 30th June 2020',

    editCodesLegend:
      'Visualise how your experience is performing on a daily basis',
    title: 'Edit Campaign',
    cancel: 'Cancel',
    back: 'Back',
    save: 'Save',
    undo: 'Undo',
    bulkImportCodes: 'Bulk import codes',
    exportCodesSuccess: 'Codes have been exported successfully',
    errorExportingCodes:
      'An error occurred when trying to export the codes, please try again later.',
    addCode: 'Add code',
    editCodes: 'Progress dashboard',
    addCodePlaceholder: 'Example: 39239',
    code: 'Code',
    usageNumber: 'Number of usages',
    usageNumberHelp:
      'Number of times that a code can be used, for infinite uses mark the checkbox',
    addUsageNumber: 'Example: 1',
    fileTypeError: 'The file you are trying to upload is not a .CSV file',
    codeAddedSuccessfully: 'Code has been added successfully',
    errorAddingCode:
      'An error occurred when trying to add the code, please try again later',
    exportUsedCodes: 'Export used codes',
    exportAvailableCodes: 'Export available codes',
    exportAllCodes: 'Export all codes',
    messages: 'Messages',
    editAddonsLegend:
      'From here you can edit all things related to your add-ons',
    addToBuy: 'Add to buy',
    addToBuyPlaceholder: 'e.g. Would you like to buy some products?',

    filter: {
      date: 'Date',
      code: 'Code',
      filter: 'Filter',
    },
    totalSkillActivations: 'Total skill activations:',
    participants: 'Participants',
    marketingPermissions: 'Marketing permissions',
    total: 'Total',
    percentage: 'Percentage',
    infiniteUsages: 'Infinite usages',
    bulkImportCodesHelp:
      'Comma separated file (CSV) with the following format: [code],[numberOfUsages];. Example: 1234,3;',
    createSuccessful: 'The campaign was successfully created',
    updateSuccessful: 'The experience was saved successfully',
    updateError: 'An error occurred during the update',
    errorGetingExperienceTypes:
      'An error occurred when trying to get the experience types, please try again later',
    bulkImportCode: {
      codesAlreadyExist:
        'The codes you are trying to add are already added to the list',
      success: 'The codes have been added successfully',
      empty:
        'We found some issues on your .cvs file, please correct them first to continue.',
      error:
        'An error occurred when trying to bulk import the codes, please try again later',
    },
    dropCodes: 'Drop your .CSV to add new codes',
    codesLegend:
      'Or you can also browse the file on your computer by clicking the button below',
    bulkImportHelp:
      'If you need a sample of our .CSV bulk import template you can ',
    bulkImportDownload: 'download it here',
    removeProductSuccess: 'The product has been removed successfully',
    removeProductError:
      'An error occurred when trying to remove the product, please try again later',
    editProduct: 'Edit Product',
    removeProduct: 'Remove Product',
    addProduct: 'Add product',
    addOnsDefaultLabel: 'Message',
    addOnsYesIntentDefaultLabel: 'Allow marketing permissions message',
    addOnsNoIntentDefaultLabel: "Don't allow marketing permissions message",
    drawWinner: 'Draw winner',
    reDrawWinner: 'Re-draw winner',
    reDrawWinnerConfirm: 'Confirm',
    reDrawWinnerCancel: 'Cancel',
    reDrawWinnerMessage: 'Are you sure that you want to draw the winner again?',
    noWinnerMessage: 'The winner was not drawn yet',
    winnerCodeTitle: 'Winner code',
    winnerTitle: 'Winner',
    winnerCodeTooltip: 'Draw the winner of the code campaign',
    winnerTooltip: 'Draw the winner of the campaign',
    winnerUserName: 'Username',
    winnerEmail: 'Email',
    winnerCode: 'Code',
    goodbyeAllowMarketingPermission: 'Goodbye allow marketing permissions',
    goodbyeDontAllowMarketingPermission:
      "Goodbye don't allow marketing permissions",

    giveawayQuiz: {
      questionAnswers: 'Edit question and answers',
      questionAnswersLegend:
        'From here you can edit the question and answers of the experience',
      messages: {
        welcome: 'Welcome',
        termsAndConditions: 'Terms and conditions',
        goodbyeWithAddOns: 'Goodbye with Add-ons',
        goodbyeWithoutAddOns: 'Goodbye without Add-ons',
        help: 'Help message',
        errorMessage: 'Error message',
        noConfirmationAnswer: 'Answer not confirmed',
        confirmationAnswer: 'Answer confirmation',
        invalidAnswer: 'Invalid answer',
        stepsWon: 'Information about how to win',
        endCompetition: 'When does the experience end?',
      },
      tooltips: {
        help:
          "A help message is displayed when a user asks for help after getting past the 'Welcome message'",
        stepsWon:
          "Information about how to win is displayed when a user asks about winning after getting past the 'Welcome message'",
        endCompetition: 'Type in information about when the experience ends',
      },
      helps: {
        welcome:
          "The question must evoke for a simple 'YES' or 'NO' answer from the users. E.g. Welcome to VozLab's quiz. Are you ready to start?'",
      },
      placeholders: {
        welcome:
          "Hi there, today's giveaway is an Elf on the Shelf package, brought to you by Kellogg's",
        termsAndConditions:
          "Great, I've sent you the terms of use to your account. Are you ready?",
        goodbyeWithAddOnsPlaceholder: 'Great! Thank you again and good luck!',
        goodbyeWithoutAddOnsPlaceholder: 'Ok! Thanks you again and good luck!',
        help:
          'This is a giveaway for NHS employees only. Answering a question you will be participating',
        errorMessage: "Sorry, I didn't get that, could you please try again?",
        noConfirmationAnswer:
          'Ok, Which is the answer for the following question?',
        confirmationAnswer: "Thank you, just to confirm. Was that's ",
        invalidAnswer: 'The answer is not a valid one, please enter a new one.',
        stepsWon:
          'Winning entrants will be notified immediately bu me and via the email you used to enter the competition',
        endCompetition:
          'The promotion is open from 01 st June 2020 until 30th June 2020',
      },
    },
    alternativePath: 'Alternative Path',
    alternativePathLegend: 'Type your alternative path messages here',
    termsAndConditions: 'Terms and conditions',
    termsAndConditionsPlaceholder:
      "Great, I've sent you the terms of use to your account. Are you ready?",
    termsAndConditionsHelp:
      "Let the user know they will receive a Terms os Service e-mail (only if the option 'Send Terms of Service e-mail' has been enabled).",
    playingInstructions: 'Playing instructions',
    playingInstructionsPlaceholder:
      "Ok, to play you must answer 1, 2 or 3 after each question. Ready? Let's get started!",
    playingInstructionsHelp:
      'The answers given by participants must be a number, e.g., to play you must answer 1, 2 or 3 after each question.',
  },
  userInformation: {
    title: 'User Information',
    legend:
      'From here you can see all gathered information of the users that interacted with your experience',
    email: 'Email',
    name: 'Name',
    searchBy: 'Search by:',
    location: 'Location',
    date: 'Date',
    apply: 'Apply',
  },
  manageSkill: {
    live: 'Live',
    offline: 'Offline',
    title: 'Manage your skill',
    legend:
      'From here you can review and edit your campaign instantly for your skill',
    confirmationOn: 'Turn on campaign',
    confirmationOff: 'Turn off campaign',
    confirmationBodyOn: 'Are you sure you want to make this campaign go live?',
    confirmationBodyOff:
      'Are you sure you want to make this campaign go offline?',
    confirmationArchive: 'Archive campaign {campaign}',
    confirmationBodyArchive:
      'Are you sure you want to archive the campaign {campaign}',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tableTitle: 'CAMPAIGNS',
    tableLegend: 'From here you can manage and review your campaigns',
    newCampaignButton: 'Add new campaign',
    archivedCampaignsButton: 'Archived campaigns',
    invocationPhrase: 'Invocation phrase',
    status: 'Status',
    offlineMessage: 'Offline skill',
    offlineMessagePlaceholder: 'Offline message placeholder',
    lengthError: 'The message is too short',
    emptyError: 'The message cannot be empty',
    invalidCharactersError: 'The message has invalid characters',
    offlineMessageUpdateSuccess: 'The skill offline message was updated',
    cloneModalTitle: 'Clone campaign',
    cloneModalBody: 'Are you sure you want to clone this campaign?',
    tableHeaders: {
      createdAt: 'Created at',
      name: 'Name',
      status: 'Status',
      type: 'Type',
      startDate: 'Start date',
      endDate: 'End date',
      actions: 'Actions',
    },
    noCampaigns:
      'There are no campaigns loaded, create a new one by clicking the "Add new campaign" button',
    alreadyActiveCampaignError: 'You already have an active campaign',
    genericError: 'Something went wrong, try again later',
    maxSamplesCampaignError: 'Cannot activate the campaign because reached the maximum samples sent',
    actions: {
      archive: 'Archive',
      edit: 'Edit',
      clone: 'Clone',
      performance: 'Performance',
      default: 'Select an option',
    },
    askForPermission: 'Ask for permission',
    askForPermissionPlaceholder: 'Ask for permission message placeholder',
    askForPermissionUpdateSuccess: 'The ask for permission message was updated',
    skillMessages: 'Skill messages',
    skillMessagesLegend: 'These messages are common to all the campaigns',
    skillMessagesTooltip: 'Changes on the messages are automatically saved',
    quickLink: 'Quick link',
    quickLinkCopy: 'Copy link',
    quickLinkCopiedMessage: 'Quick link copied to clipboard',
    quickLinkTooltip:
      'Quick links allow users to instantly reach and launch your skill',
    campaignArchivedSuccessfully: 'Campaign archived successfully',
  },
  editCampaignHeader: {
    campaign: 'CAMPAIGN',
    generalDataTitle: 'GENERAL DATA',
    experienceType: 'Experience type',
    startDate: 'Started on:',
    endDate: 'Ended on:',
    separator: '•',
    changeExperienceTypeDisclaimer:
      'Changing the experience type will change the messages accordingly',
  },
  toggle: {
    on: 'ON',
    off: 'OFF',
  },
  experienceTypes: {
    CODE: 'Giveaway - Code',
    BASIC: 'Single Message',
    QUIZ: 'Quiz',
    INSTANT_WIN: 'Instant Win (BETA)',
    GIVEAWAY_QUESTION: 'Giveaway - Question',
    QUIZ_SUGGESTION: 'Product suggestions',
    GIVEAWAY_NO_ENTRY_BARRIER: 'Giveaway - No Entry Barrier (BETA)',
    SAMPLE: 'Sample (BETA)',
    DISCOUNT_CODE: 'Discount Code (BETA)',
    HASHTAG: 'Hashtag (BETA)',
    SURVEY: 'Survey (BETA)',
    LISTEN_WIN: 'Listen and Win (BETA)',
    COUNTDOWN_REVEAL: 'Countdown Reveal (BETA)',
  },
  newCampaignModal: {
    buttons: {
      cancel: 'Cancel',
      create: 'Create campaign',
      clone: 'Clone campaign',
    },
    fields: {
      name: 'Name',
      type: 'Experience type',
      dateFrom: 'Start date',
      dateTo: 'End date',
    },
    placeholders: {
      name: 'Campaign name',
      type: 'Select an option',
      date: 'Select a date',
    },
    newCampaignTitle: 'New campaign',
    cloneCampaignTitle: 'Clone campaign - {name}',

    experienceTypeDescriptions: {
      CODE:
        'With this experience you can run giveaways, using a code as an entry mechanic. This is a very popular experience for on-pack promotions that require proof of purchase. Or you can also use this experience to track the effectiveness of your marketing channels, by giving different codes depending on the channel. For example, you can use a code for social media promotions and another one for radio advertising.',
      BASIC:
        'Use this experience as a stop-gap between campaigns. It will allow you to continue using this channel to sell your products, or to generate newsletter sign ups to grow your fan base.',
      QUIZ:
        'Quizzes on Alexa are one of the most popular ways to interact with fans. Use this experience to show your fun size, and let your true fans show you how much they know about your brand.',
      GIVEAWAY_QUESTION:
        'This is the perfect experience to run giveaways using a multiple-choice question as an entry mechanic. Use this experience to reinforce your brand message or values, or something you want your users to remember about you, or just use it as a fun, different way of doing giveaways.',
      QUIZ_SUGGESTION: 'Sample text',
    },
  },
  forms: {
    restrictions: {
      required: 'This field is required',
      minLength: `The minimum length is {length} characters`,
      maxLength: `The maximum length is {length} characters`,
      invalidCharacters: `The field has invalid characters`,
      number: 'This field must be a number',
      moreThanZero: 'This field must be higher than zero',
      moreThanValue: 'This field must be higher or equal to {value}',
      lessThanValue: 'This field must be less or equal to {value}',
      question: "This field must end with a question mark '?'",
      yesOrNo: "Options can't be a single YES or NO",
      noLetter: 'This field must contain a letter',
    },
  },
  table: {
    body: {
      message: {
        defaultEmptyMessage: 'There are no items loaded',
      },
    },
  },
  performance: {
    title: 'Performance',
    legend: 'Visualise how your experience is performing on a daily basis.',
    skillActivations: 'skill activations',
    totalParticipants: 'participants',
    participationRate: '{percentage}% participation rate',
    marketingPermission: 'marketing permissions',
    samplesRequested: 'samples requested',
    emailsSent: 'emails sent',
    addToBuy: 'add to buy',
    totalQuestions: 'total questions',
    conversionRate: '{percentage}% conversion rate',
    purchaseRate: '{percentage}% purchase rate',
    correctAnswersRate: '{percentage}% correct answers rate',
    samplesRequestedRate: '{percentage}% samples requested rate',
    emailsSentRate: '{percentage}% emails sent rate',
  },
  addProduct: {
    code: 'Code',
    codePlaceholder: "What is the product's code?",
    name: 'Name',
    namePlaceholder: "What is the product's name?",
    description: 'Description',
    descriptionPlaceholder: "What is the product's description?",
    errorAddingProduct: 'An error occurred when trying to save the product',
    successAddingProduct: 'The product has been saved successfully',
  },
  general: {
    apply: 'Apply',
    requiredField: 'This is a required field',
    requiredNumber: 'This field should be a number',
  },
  errors: {
    gettingCampaign:
      'An error occurred when trying to get the campaign information, please try again later.',
    gettingSkillData:
      'An error occurred when trying to get the skill performance, please try again later.',
  },
  analytics: {
    title: 'Analytics',
    legend: 'Here you can get insights of your skill performance',
    errorGettingAnalyticsFromCampaign:
      'An error occurred when trying to get the analytics, please try again later',
    dateRangePicker: 'Select date range:',
    uniqueCustomers: 'Unique customers',
    uniqueCustomersLegend:
      'Total number of unique users who accessed your skill.',
    totalEnablements: 'Total enablements',
    totalEnablementsLegend: 'Unique user accounts that enabled a skill.',
    totalSessions: 'Total sessions',
    totalSessionsLegend:
      'Total sessions, this includes successful sessions, incomplete sessions, failed sessions and in-progress/expired sessions.',
    successfulSessions: 'Successful sessions',
    successfulSessionsLegend:
      'Total successful sessions that are user-ended or skill-ended.',
    incompleteSessions: 'Incomplete sessions',
    incompleteSessionsLegend:
      'Total sessions that were ended due to user non-response.',
    userEndedSessions: 'User ended sessions',
    userEndedSessionsLegend:
      'Total user-ended sessions. This happens when the user explicitly ends the interaction.',
    skillEndedSessions: 'Skill ended sessions',
    skillEndedSessionsLegend:
      'Total skill-ended sessions. This happens when Alexa ends the interaction.',
    loading: 'Loading analytics',
  },
  uploader: {
    imageSize:
      'The image cannot exceed {width} pixels width and {height} pixels height',
    imageTooBig:
      'The image exceeded the pixel limit, must have {width} pixels width and {height} pixels height.',
  },
  drawCodeWinnerModal: {
    close: 'Close',
    loading: 'Drawing winner...',
    title: 'Draw winner',
    winnerSubtitle: 'Winner data',
  },
  zeroState: {
    questionsAnswersTitle: 'No questions found.',
    questionsAnswersLegend:
      'You can start adding questions by clicking on the add question button bellow.',
    addQuestion: 'Add question',
  },
  editQuestionsAnswers: {
    answer: '{number}. Answer',
    answerPlaceholder: 'Enter you answer here',
    isCorrectAnswer: 'Is the correct answer',
    addAnswer: 'Add Answer',
    removeQuestion: 'Remove Question',
    addQuestion: 'Add new question',
    question: '{number}. Question',
    questionError: 'There are errors in this question',
    questionPlaceholder: 'Enter your question here',
    editQuestionsAnswers: 'Edit Questions and Answers',
    questionsAnswersLegend:
      'Type to edit the questions and answers of the experience',
    panelError: 'There are errors under this panel',
    addQuestionMaxReached: 'Maximum number of questions reached',
    addAnswerMaxReached: 'Maximum number of answers reached',
    questionLengthError: 'The campaign must have at least one question',
  },

  editQuestionAnswers: {
    answer: '{number}. Answer',
    answerPlaceholder: 'Enter you answer here',
    isCorrectAnswer: 'Is the correct answer',
    addAnswer: 'Add Answer',
    questionSingle: 'Question',
    questionPlaceholder: 'Enter your question here',
    editQuestionsAnswers: 'Edit Questions and Answers',
    questionsAnswersLegend:
      'From here you can edit all the questions and answers of the experience',
    panelError: 'There are errors under this panel',
    addQuestionMaxReached: 'Maximum number of questions reached',
    addAnswerMaxReached: 'Maximum number of answers reached',
  },
  getAudio: {
    title: 'Voice Parameters',
    speech: 'Speech',
    language: 'Language',
    english: 'English',
    spanish: 'Spanish',
    french: 'French',
    german: 'German',
    narrator: 'Narrator',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    ageBracket: 'Age bracket',
    adult: 'Adult',
    child: 'Child',
    senior: 'Senior',
    close: 'Close',
    save: 'Save',
    additionalSettings: 'Additional Settings',
    tags: 'Tags',
    speed: 'Speed',
    accent: 'Accent',
    slow: 'Slow',
    normal: 'Normal',
    fast: 'Fast',
    getAudio: 'Generate audio',
    messages: {
      success: 'The voice message was generated successfully',
      errorGeneric: 'There was an error generating the audio',
      noVoicesFound: 'There are no voices for the selected filters',
      generatingAudio: 'Generating audio...',
      changesNotReflected:
        'The changes are not updated until you generate the audio again',
      generateDisabledText:
        'Disabled since there are no changes in the form since the last audio generation.',
    },
  },
  textArea: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    getAudio: 'Get audio',
    playAudio: 'Play audio',
    stopAudio: 'Stop audio',
    deleteAudio: 'Delete audio',
    deleteAudioTitle: 'Delete audio',
    removeAudioSuccess: 'Audio deleted successfully.',
    removeAudioError: 'There was an error deleting the audio',
    deleteAudioMessage:
      'Are you sure you want to delete the {messageKey} audio?',
  },
  suggestions: {
    editSuggestions: 'Edit suggestions',
    suggestionPlaceholder: 'Enter suggestion',
    displaySuggestions: 'Display options for:',
    closeButton: 'Close',
    save: 'Save',
  },

  defaultMessages: {
    initialHelpMessage: 'Initial help message',
    initialStepsWon: 'Initial information about how to win',
    initialEndCompetition: 'When does the experience end?',
    initialHelpMessagePlaceholder: 'Please write the initial help message',
    initialStepsWonPlaceholder: 'Please write the initial steps won message',
    initialEndCompetitionPlaceholder:
      'Please write the initial end competition message',
    initialHelpMessageHelp:
      "An initial help message is displayed when a user asks for help straight after the 'welcome message'",
    initialStepsWonHelp:
      "Information about how to win is displayed when a user asks about winning straight after the 'welcome message'",
    initialEndCompetitionHelp:
      'Type in information about when the experience end',
  },

  editQuestionsAndAnswers: {
    questionLengthError: 'The campaign must have at least one question',
    suggestionsError: 'There are some errors in the suggestions',
    newQuestionPlaceholder: 'Type a question',
    newOptionPlaceholder: 'Type an option',
    addQuestion: 'Add question',
    editQuestion: 'Edit question',
    add: 'Add',
    edit: 'Save',
    cancel: 'Cancel',
    twoOptionsWarning: 'The question must have at least two options',
    eachOptionTwoOptionWarning: 'Each case must have at least two options',
    editQuestionsTitle: 'Edit questions and options',
    editQuestionsLegend:
      'Type to edit the questions and options of the experience',
    deleteQuestionModalTitle: 'Delete question {questionId}',
    deleteQuestionModalMessage: 'Are you sure you want to delete the question?',
    deleteQuestionModalImportant: 'Important: ',
    deleteQuestionModalDisclaimer:
      'Deleting a question will delete all the next questions and also clean the suggestions.',
    yesOrNoOption: "Question must evoke a 'YES' or 'NO'",

    messages: {
      welcome: 'Welcome',
      termsAndConditions: 'Terms and conditions',
      suggestion: 'Suggestion',
      defaultSuggestion: 'Default suggestion',
      goodbyeWithAddOns: 'Goodbye with Add-ons',
      goodbyeWithoutAddOns: 'Goodbye without Add-ons',
      help: 'Help message',
      stepsWon: 'Information about how to win',
      endCompetition: 'When does the experience end?',
      exitMessage: 'Exit message',
    },
    tooltips: {
      help:
        "A help message is displayed when a user asks for help after getting past the 'Welcome message'",
      stepsWon:
        "Information about how to win is displayed when a user asks about winning after getting past the 'Welcome message'",
      endCompetition: 'Type in information about when the experience ends',
    },

    placeholders: {
      welcome: 'Welcome to Mucinex, Would you like to check your symptoms?',
      termsAndConditions:
        "Great, I've sent you the terms of use to your account. Are you ready?",
      suggestion: 'Thank you for that. The best product for you is',
      defaultSuggestion:
        'Sorry, at the moment we dont have a product to offer to you, please come back later.',
      goodbyeWithAddOns: 'Great! Thank you again and good luck!',
      goodbyeWithoutAddOns: 'Ok! Thank you again and good luck!',
      help:
        'This a quiz with suggestions,  you need to answer in order to provide the best product for you',
      stepsWon: 'You can win a free usefull recomendation',
      endCompetition: 'The idea is to have this for a long time',
      exitMessage: 'Thanks for your participation.',
    },
  },
  addOns: {
    editPreAddOns: 'Edit Previous Add-Ons',
    editPreAddOnsDescription:
      'From here you can edit all things related to your pre add-ons',
    preQuestion: {
      preQuestionTitle: 'Pre question',
      preQuestionMessage: 'Question',
      preQuestionComply: 'Affirmative answer',
      preQuestionNotComply: 'Negative answer',
      preQuestionComplyPlaceholder: "Great, let's continue!",
      preQuestionNotComplyPlaceholder:
        "I'm sorry, you need to be over 18 in order to use this skill.",
      preQuestionPlaceholder: 'Are you over 18?',
    },
    sample: {
      sampleMessage: 'Sample',
      question: 'Question',
      questionPlaceholder: 'Would you like to receive a free sample?',
      complyAnswer: 'Affirmative answer',
      complyPlaceholder: 'Great! We will send a sample!',
      notComplyAnswer: 'Negative answer',
      notComplyAnswerPlaceholder: 'Ok! Thank you again',
      maxSamples: 'Maximum samples',
      maxSamplesPlaceholder: '0',
      maxSamplesHelpMessage:
        'Maximum number of samples that can be sent to unique users, the campaign will deactivate once the limit is reached',
      addressValidation: 'Address Validation',
    },
    email: {
      emailMessage: 'E-mail',
      question: 'Question',
      questionPlaceholder: 'Would you like to receive an e-mail?',
      yesAnswer: 'Affirmative answer',
      yesPlaceholder: 'Great! We will send a sample!',
      notAnswer: 'Negative answer',
      notAnswerPlaceholder: 'Ok! Thank you again',
    },
  },
  reports: {
    addToBuyTitle: 'Add to buy purchased',
    addToBuyPurchased: 'Export add to buy purchased',
    addToBuyPurchasedSuccess: 'Add to buy report exported successfully',
    addToBuyPurchasedError: 'There was an error exporting the report',
    sampleTitle: 'Samples requested',
    sampleRequested: 'Export samples requested',
    sampleRequestedSuccess: 'Sample report exported successfully',
    sampleRequestedError: 'There was an error exporting the report',
    uniqueSampleTitle: 'Unique samples requested',
    uniqueSampleRequested: 'Export unique samples requested',
    uniqueSampleRequestedSuccess: 'Unique sample report exported successfully',
    uniqueSampleRequestedError: 'There was an error exporting the report',
    emailsSent: 'Export e-mails sent',
    emailsSentSuccess: 'E-mails report exported successfully',
    emailsSentTitle: 'E-mails sent',
    emailsSentError: 'There was an error exporting the report',
    marketingPermissionTitle: 'Marketing permission data',
    marketingPermissionData: 'Export marketing permission data',
    marketingPermissionRequestedSuccess:
      'Marketing permissions report exported successfully',
    marketingPermissionRequestedError:
      'There was an error exporting the report',
    skillActivations: 'Export skill activations',
    skillActivationsTitle: 'Skill activations',
    skillActivationsRequestedSuccess:
      'Skill activations report exported successfully',
    skillActivationsRequestedError: 'There was an error exporting the report',
  },
  archivedCampaignsModal: {
    campaignRestoredSuccessfully: 'Campaign restored successfully',
    modalTitle: 'Archived campaigns',
    noCampaigns: 'There are no archived campaigns',
    tableHeaders: {
      createdAt: 'Created at',
      name: 'Name',
      type: 'Type',
      startDate: 'Start date',
      endDate: 'End date',
      actions: 'Actions',
    },
    actions: {
      restore: 'Restore',
      performance: 'Performance',
      default: 'Select an option',
    },
  },
}
