import styled, { css } from 'styled-components'

export const LoaderWrapper = styled.div`
  width: 100%;
  text-align: center;

  ${(props) =>
    props.center
      ? css`
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : ''}
`
