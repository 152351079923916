import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { signOut } from 'actions/auth'

import Menu from 'components/Menu'
import UserInformation from 'components/UserInformation'

import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as ManageIcon } from 'assets/icons/manage.svg'
import { ReactComponent as TimesIcon } from 'assets/icons/times.svg'

import StyledSidebar from './sidebar.styles'

const Sidebar = ({ setIsCollapsed, isCollapsed }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const logOut = async () => {
    await dispatch(signOut())
  }

  return (
    <StyledSidebar isCollapsed={isCollapsed}>
      <div className="sidebar__collapse" onClick={() => setIsCollapsed(true)}>
        <TimesIcon />
      </div>
      <UserInformation />
      <div className="sidebar__menu">
        <Menu>
          <Menu.Title text="sidebar.experiences" />
          <Menu.Item
            onClick={() => history.push('/')}
            icon={ManageIcon}
            text="sidebar.manage"
          />

          <Menu.Title text="sidebar.information" />
          <Menu.Item
            icon={AnalyticsIcon}
            text="sidebar.analytics"
            onClick={() => {
              history.push('/analytics')
            }}
          />
        </Menu>
      </div>
      <div className="sidebar__footer">
        <Menu.Item onClick={logOut} icon={LogoutIcon} text="sidebar.logout" />
      </div>
    </StyledSidebar>
  )
}

Sidebar.propTypes = {
  setIsCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
}

export default Sidebar
