import styled from 'styled-components'
import { palette } from 'styled-theme'

export default styled.h3`
  color: ${palette('black', 0)};
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  margin: 1.6rem 0;
  text-transform: uppercase;
`
