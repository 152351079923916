import styled, { css } from 'styled-components'
import { palette } from 'styled-theme'

export default styled.aside`
  background: ${palette('white', 3)};
  box-shadow: ${({ theme }) => theme.boxShadows[0]};
  min-height: 100vh;
  opacity: 1;
  padding: 4rem 2rem;
  position: fixed;
  transition: transform 0.5s ease-in-out;
  width: 22.8rem;
  ${(props) =>
    props.isCollapsed &&
    css`
      transform: translateX(-22.8rem);
    `}

  .sidebar {
    &__menu {
      margin-top: 3.4rem;
    }
    &__footer {
      bottom: 3rem;
      position: absolute;
    }
    &__collapse {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;

      > svg {
        width: 2rem;
      }

      @media (min-width: 480px) {
        display: none;
      }
    }
  }
`
