/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {
  happyPath: [],
  alternativePaths: [],
}

const setPaths = (draftState, action) => {
  draftState = {
    happyPath: action.happyPath,
    alternativePaths: action.alternativePaths,
  }
  return draftState
}

const handlers = {
  [`${Types.SET_MESSAGE_PATHS}`]: setPaths,
}

const drawerReducer = createReducer(initialState, handlers)

export default drawerReducer
