const authTypes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
}

const campaignTypes = {
  SET_CURRENT_CAMPAIGN: 'SET_CURRENT_CAMPAIGN',
}

const companyTypes = {
  SET_COMPANY: 'SET_COMPANY',
  SET_EXPERIENCE_TYPES: 'SET_EXPERIENCE_TYPES',
}

const generalTypes = {
  SET_EXPERIENCE_TYPES: 'SET_EXPERIENCE_TYPES',
  SET_EDIT_EXPERIENCE_DRAWER_STATE: 'SET_EDIT_EXPERIENCE_DRAWER_STATE',
}

const messagePathsTypes = {
  SET_MESSAGE_PATHS: 'SET_MESSAGE_PATHS',
}

const audioInformation = {
  SET_AUDIO_INFORMATION: 'SET_AUDIO_INFORMATION',
}

const performance = {
  SET_FILTER_DATE_PERIOD: 'SET_FILTER_DATE_PERIOD',
}

export default {
  ...authTypes,
  ...campaignTypes,
  ...companyTypes,
  ...generalTypes,
  ...messagePathsTypes,
  ...audioInformation,
  ...performance,
}
