/* eslint-disable no-param-reassign */
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {}

const setCurrentCampaign = (draftState, action) => {
  draftState = { ...action }
  return draftState
}

const handlers = {
  [`${Types.SET_CURRENT_CAMPAIGN}`]: setCurrentCampaign,
}

const campaignReducer = createReducer(initialState, handlers)

const campaignPersistConfig = {
  storage,
  key: 'currentCampaign',
}

export default persistReducer(campaignPersistConfig, campaignReducer)
