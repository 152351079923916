import enUSMessages from 'languageProvider/messages/en-US'

const enUSLanguage = {
  messages: {
    ...enUSMessages,
  },
  locale: 'en-US',
}

export default enUSLanguage
