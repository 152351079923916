import React from 'react'

import { useSelector } from 'react-redux'
import StyledUserInformation from './userinformation.styles'

const UserInformation = () => {
  const { companyName, companyEmail } = useSelector((state) => state.company)

  return (
    <StyledUserInformation>
      <div className="user__wrapper">
        <span className="user__name">{companyName}</span>
        <span className="user__email">{companyEmail}</span>
      </div>
    </StyledUserInformation>
  )
}

export default UserInformation
