import AuthService from 'api/AuthService'
import Types from './types'

export const signIn = (credentials) => ({
  type: Types.SIGN_IN,
  payload: AuthService.signIn(credentials),
})

export const signOut = () => ({
  type: Types.SIGN_OUT,
  payload: AuthService.signOut(),
})
