import styled from 'styled-components'
import { palette } from 'styled-theme'

export default styled.div`
  display: flex;
  justify-content: left;
  .user {
    &__image {
      border-radius: 10rem;
      box-shadow: 4rem 0.4rem 0.8rem 0.4rem rgba(210, 210, 210, 0.15);
      height: 4.6rem;
      width: 4.6rem;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__name {
      color: ${palette('black', 3)};
      font-size: 1.4rem;
      font-weight: 600;
    }
    &__email {
      color: ${palette('black', 1)};
      font-size: 1.3rem;
      font-weight: 400;
      max-width: 18rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`
