import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as HamburgerMenu } from 'assets/icons/hamburgermenu.svg'

import StyledMainLayout from './mainlayout.styles'

const MainLayout = ({ children, setIsCollapsed, isCollapsed }) => (
  <StyledMainLayout isCollapsed={isCollapsed}>
    <div
      className={`hamburger-menu-container ${isCollapsed && 'show'}`}
      onClick={() => setIsCollapsed(false)}
    >
      <HamburgerMenu />
    </div>
    {children}
  </StyledMainLayout>
)

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
}

export default MainLayout
