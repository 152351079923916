import { createGlobalStyle } from 'styled-components'
import { palette } from 'styled-theme'

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 62.5%;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    /* This is needed to get rid of a padding set by rsuite when opening the modal */
    padding: 0 !important;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
      color: #ffffff;
      border-color: ${palette('purple', 0)};
      background-color: ${palette('purple', 0)};
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid ${palette('purple', 0)};
    padding: 4px;
  }

  .rs-picker-toolbar-option {
    color: ${palette('purple', 0)};
  }
`
