import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from 'store'

import * as serviceWorker from './serviceWorker'

import App from './app'
import { ENVIRONMENT_DEV } from './utils/constants/constants'

let reactRender = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT_DEV) {
  reactRender = <React.StrictMode>{reactRender}</React.StrictMode>
}

ReactDOM.render(reactRender, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
