/* eslint-disable no-param-reassign */
import Types from '../actions/types'
import createReducer from './createReducer'

const initialState = {}

const setFilterDatePeriod = (draftState, action) => {
  draftState = { ...action }
  return draftState
}

const handlers = {
  [`${Types.SET_FILTER_DATE_PERIOD}`]: setFilterDatePeriod,
}

const performanceReducer = createReducer(initialState, handlers)

export default performanceReducer
