import React from 'react'
import { Loader } from 'rsuite'

import { LoaderWrapper } from './Loader.styles'

const CustomLoader = ({ ...rest }) => (
  <LoaderWrapper {...rest}>
    <Loader {...rest} />
  </LoaderWrapper>
)

export default CustomLoader
