import React, { lazy, Suspense } from 'react'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'

import flatten from 'flat'

import { useAuthentication } from 'utils/hooks/auth'
import { GlobalStyle } from 'app/App.styles'
import { theme } from 'settings/theme/default'
import AppLocale from 'languageProvider'
import ErrorBoundary from 'components/ErrorBoundary'
import Loading from 'components/Loader'

import AuthenticatedApp from 'app/AuthenticatedApp'
import 'rsuite/dist/styles/rsuite-default.css'
import './vozlab.css'

const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'))

function App() {
  const currentLocale = navigator.language
  const currentAppLocale =
    currentLocale in AppLocale ? AppLocale[currentLocale] : AppLocale.en
  const { isAuthenticated } = useAuthentication()

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={currentLocale}
        messages={flatten(currentAppLocale.messages)}
      >
        <BrowserRouter>
          <GlobalStyle />
          <ErrorBoundary>
            <Suspense fallback={<Loading center size="lg" />}>
              {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  )
}

export default App
