import { combineReducers } from 'redux'

import auth from './auth'
import currentCampaign from './campaign'
import company from './company'
import general from './general'
import drawer from './drawer'
import messagePaths from './messagePaths'
import audioInformation from './audioInformation'
import performance from './performance'

export default combineReducers({
  auth,
  currentCampaign,
  company,
  general,
  drawer,
  messagePaths,
  audioInformation,
  performance,
})
