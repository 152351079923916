import styled, { css } from 'styled-components'

export default styled.main`
  margin-left: 22.8rem;
  min-height: 100vh;
  transition: margin-left .5s ease-in-out;
  width: 100%;
  
  ${(props) =>
    props.isCollapsed &&
    css`
      margin-left: 0rem;
    `}

  .hamburger-menu-container {
      left: 2rem;
      opacity: 0;
      position: absolute;
      top: 2rem;
      transition: opacity .5s ease-in-out;
      width: 2rem;

      @media (min-width: 480px) {
        display: none;
      }
    }
    
    .show {
      opacity: 1;
    }
  }
`
