export const theme = {
  palette: {
    orange: ['#EA580C', '#EA580C', '#ED8936', '#DD6B20'],
    purple: ['#667EEA', '#5A67D8', '#4C51BF'],
    black: ['#A0AEC0', '#718096', '#4A5568', '#2D3748'],
    white: ['#F7FAFC', '#EDF2F7', '#E2E8F0', '#ffffff', '#9CA3AF'],
    red: ['#e53e3e', '#F56565', '#FC8181'],
    green: ['#38A169', '#48BB78', '#68D391'],
    yellow: ['#FAF089', '#F6E05E', '#c89e04'],
  },
  boxShadows: ['0.4rem 0rem .8rem 0.4rem rgba(210,210,210,.15)'],
  flexboxgrid: {
    gutterWidth: 3,
  },
}
