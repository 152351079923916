/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {}

const setCompany = (draftState, action) => {
  draftState = { ...action }
  return draftState
}

const handlers = {
  [`${Types.SET_COMPANY}`]: setCompany,
}

const companyReducer = createReducer(initialState, handlers)

export default companyReducer
