/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {
  isEditExperienceDrawerOpen: false,
}

const setEditExperienceDrawerState = (draftState, action) => {
  draftState = { isEditExperienceDrawerOpen: action }
  return draftState
}

const handlers = {
  [`${Types.SET_EDIT_EXPERIENCE_DRAWER_STATE}`]: setEditExperienceDrawerState,
}

const drawerReducer = createReducer(initialState, handlers)

export default drawerReducer
