/* eslint-disable no-template-curly-in-string */

// Defines de min length of the skill offline message
export const MIN_LENGTH_SKILL_OFFLINE_MESSAGE = 15

// Defines the text debounce
export const DEBOUNCE_TEXT = 500

// Defines a one second debounce time
export const DEBOUNCE_TIME_ONE_SECOND = 1000

// Defines redirection timeout
export const REDIRECTION_TIMEOUT = 2000

// Defines a time interval that a message is showed
export const MESSAGE_TIMEOUT = 4000

// Defines a time interval that an alert is showed
export const ALERT_DURATION = 4000

// Defines the date format
export const DATE_FORMAT = 'DD/MM/YYYY'

// Defines the messages max length
export const MESSAGE_MAX_LENGTH = 500

// Defines a regular expression in order to validate text inputs
export const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9\s]+$/

// Defines a regular expression for messages inputs
export const ALLOWED_CHARACTERS_MESSAGES_REGEX = /^[a-zA-Z0-9àèéìíîòóùú.,'!?\s]+$/

// Defines a regular expression for a string that must contain a letter
export const VALIDATION_ALEXA_INPUT_REGEX = /[a-zA-Z0-9àèéìíîòóùú]/

// Defines a regular expression for messages inputs that doesn't allow questions
export const ALLOWED_CHARACTERS_MESSAGES_NO_QUESTION_REGEX = /^[a-zA-Z0-9àèéìíîòóùú.,'!\s]+$/

// Defines a regular expression for suggestions
export const ALLOWED_CHARACTERS_SUGGESTIONS_REGEX = /^[a-zA-Z0-9.,\-'!?\s]+$/

export const ALLOWED_QUESTION_REGEX = /(.*\?$)/

// Defines a medium toggle size
export const TOGGLE_SIZE_MEDIUM = 'medium'

// Defines a large toggle size
export const TOGGLE_SIZE_LARGE = 'large'

// Defines a very large toggle size
export const TOGGLE_SIZE_VERY_LARGE = 'veryLarge'

// Defines the max size of the undo array
export const UNDO_MAX_SIZE = 5

// "Z" Keyboard key code
export const Z_KEY_CODE = 90

// Quick link URL
export const QUICK_LINK_URL =
  'https://alexa-skills.amazon.${domain}/apis/custom/skills/${skillId}/launch'

// --------- HEADER ACTIONS ----------
export const HEADER_PERFORMANCE_ACTIONS = 'HEADER_PERFORMANCE_ACTIONS'
export const HEADER_EDITION_ACTIONS = 'HEADER_EDITION_ACTIONS'

// --------- IMAGE UPLOADER -----------

// Image uploader max width
export const IMAGE_UPLOADER_DEFAULT_WIDTH = 800

// Image uploader max height
export const IMAGE_UPLOADER_DEFAULT_HEIGHT = 800

// --------- ADD ONS -----------

export const MARKETING_PERMISSIONS = 'MARKETING_PERMISSIONS'
export const ADD_TO_BUY = 'ADD_TO_BUY'
export const PRE_QUESTION = 'PRE_QUESTION'
export const SAMPLE = 'SAMPLE'
export const EMAIL = 'EMAIL'

// ---------- EXPERIENCE TYPES -----------

export const CODE = 'CODE'
export const BASIC = 'BASIC'
export const QUIZ = 'QUIZ'
export const GIVEAWAY_QUESTION = 'GIVEAWAY_QUESTION'
export const QUIZ_SUGGESTION = 'QUIZ_SUGGESTION'

// ---------- QUIZ EXPERIENCE -----------
export const QUIZ_MAX_QUESTIONS = 10
export const QUIZ_MAX_ANSWER_PER_QUESTION = 4
export const QUIZ_MIN_ANSWER_PER_QUESTION = 2
export const QUIZ_QUESTION_MAX_LENGTH = 250
export const QUIZ_ANSWER_MAX_LENGTH = 100

// ---------- GIVEAWAY QUIZ EXPERIENCE -----------
export const GIVEAWAY_QUIZ_MAX_ANSWER_PER_QUESTION = 10

// ---------- QUIZ SUGGESTIONS -----------
export const MAX_QUESTION_OPTIONS = 4
export const MAX_SUGGESTION_QUESTIONS = 6
export const MIN_OPTIONS_PER_QUESTION = 2
export const YES_ANSWER = 'YES'
export const NO_ANSWER = 'NO'


// ---------- Alexa Exchange
export const USER = 'USER'
export const ALEXA = 'ALEXA'

// ---------- AFLR AUDIO API ----------
export const AUDIO_MESSAGE_MAX_LENGTH = 500

// ---------- AFLR AUDIO API ----------
export const ENVIRONMENT_DEV = 'DEV'

// ---------- ADD TO BUY ----------
export const ADD_TO_BUY_CODE_MAX_LENGTH = 25
export const ADD_TO_BUY_NAME_MAX_LENGTH = 25
export const ADD_TO_BUY_DESCRIPTION_MAX_LENGTH = 250

// ---------- SAMPLE ----------
export const MAX_SAMPLE_VALUE = 10000
