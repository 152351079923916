import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import StyledItem from './item.styles'

const Item = ({ icon: Icon, text, onClick }) => (
  <StyledItem onClick={onClick}>
    {Icon && <Icon className="item__icon" />}
    <span className="item__text">
      <FormattedMessage id={text} />
    </span>
  </StyledItem>
)

Item.defaultProps = {
  icon: null,
  onClick: () => {},
}

Item.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default Item
