import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import StyledTitle from './title.styles'

const Title = ({ text }) => (
  <StyledTitle>
    <FormattedMessage id={text} />
  </StyledTitle>
)

Title.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Title
