import axios from 'axios'

import { store } from 'store'

const axiosClient = () => {
  const { userSession } = store.getState().auth
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (userSession) {
    const { accessToken, tokenType } = userSession
    headers = {
      ...headers,
      [tokenType]: accessToken,
    }
  }

  const client = axios.create({
    baseURL: 'https://api.vozlab.co.uk/',
    // baseURL: 'http://localhost:80',
    headers,
  })

  client.interceptors.response.use(
    (response) => response,
    ({ response }) => {
      if (!response) {
        throw new Error('Connection Error')
      }

      // if (response.status === 401 && userSession) {
      //   store.dispatch(signOut())
      // }

      throw response.data
    }
  )

  return client
}

export default axiosClient
