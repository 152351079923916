import axiosClient from './httpClient'

class CompanyService {
  static getCompany = () => axiosClient().get('/general/company')

  static updateCompany = (skillActive, skillMessages) =>
    axiosClient().put('general/company', {
      skillActive,
      skillMessages,
    })
}

export default CompanyService
