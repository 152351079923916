import styled from 'styled-components'
import { palette } from 'styled-theme'

export default styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.8rem;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  .item {
    &__icon {
      width: 1.8rem;
    }
    &__text {
      color: ${palette('black', 2)};
      font-size: 1.4rem;
      margin-left: 1rem;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out;
    }
  }
  
  &:hover {
    background: ${palette('white', 1)};
    .item {
      &__text {
          color: ${palette('orange', 3)};
      } 
  }
`
